@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=PT+Serif:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Montserrat';
}


.growing-underline {
  display: block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}

.growing-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: black;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.growing-underline:hover::after,
.growing-underline:focus::after {
  transform: translate3d(0, 0, 0);
}


.sectionSize {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  
  @media (min-width: 768px){
    .sectionSize{
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }
  
  @media (min-width: 1024px){
    .sectionSize{
      padding-left: 12rem;
      padding-right: 12rem;
    }
  }