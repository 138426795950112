.secondaryTitle {
    background-position: left bottom;
    background-repeat: no-repeat;
    font-family: PT Serif, serif;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 2rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 2rem;
  }