.slider {
  position: relative;
  height: 100%; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide {
  position: absolute;
  z-index: 10;
  opacity: 0;
  transition: opacity 1s linear;
}

.slide.active {
  opacity: 1;
}

.slide > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
